document.addEventListener("turbo:load", () => {
  const mapContainer = document.getElementById("map-search");
  if (mapContainer) {
    initMap();
  }
});

async function fetchProperties() {
  try {
    const mapContainer = document.getElementById("map-container-grid");
    const spinner = createSpinner();
    mapContainer.appendChild(spinner);

    const searchParams = new URLSearchParams(window.location.search);
    const path = window.location.pathname;

    let apiUrl;
    if (searchParams.has("market")) {
      const marketSlug = searchParams.get("market");
      searchParams.set("per_page", "5000");
      apiUrl = `/api/properties/search?market=${encodeURIComponent(
        marketSlug
      )}&${searchParams.toString()}`;
    } else if (path.startsWith("/metro/")) {
      const marketSlug = path.split("/metro/")[1];
      searchParams.set("market", marketSlug);
      searchParams.set("per_page", "5000");
      apiUrl = `/api/properties/search?${searchParams.toString()}`;
    } else {
      searchParams.set("per_page", "5000");
      apiUrl = `/api/properties/search?${searchParams.toString()}`;
    }

    const response = await fetch(apiUrl);
    const data = await response.json();

    mapContainer.removeChild(spinner);

    return data;
  } catch (error) {
    console.error("Error fetching property data:", error);
    const mapContainer = document.getElementById("map-container-grid");
    const spinner = document.getElementById("map-loader");
    if (spinner) {
      mapContainer.removeChild(spinner);
    }

    return [];
  }
}

function createSpinner() {
  const spinner = document.createElement("div");
  spinner.id = "map-loader";
  spinner.style.position = "absolute";
  spinner.style.top = "0";
  spinner.style.left = "0";
  spinner.style.width = "100%";
  spinner.style.height = "100%";
  spinner.style.backgroundColor = "rgba(255, 255, 255, 0.8)";
  spinner.style.display = "flex";
  spinner.style.justifyContent = "center";
  spinner.style.alignItems = "center";
  spinner.style.zIndex = "1000";

  const spinnerElement = document.createElement("div");
  spinnerElement.className = "spinner-border text-primary";
  spinnerElement.role = "status";
  spinnerElement.style.width = "3rem";
  spinnerElement.style.height = "3rem";
  spinnerElement.style.borderWidth = "0.4rem";
  spinnerElement.style.borderRadius = "50%";
  spinnerElement.style.borderTopColor = "#2F92D4";
  spinnerElement.style.borderRightColor = "#2F92D4";
  spinnerElement.style.borderBottomColor = "#2F92D4";
  spinnerElement.style.borderLeftColor = "transparent";

  const spinnerText = document.createElement("span");
  spinnerText.className = "visually-hidden";
  spinnerText.textContent = "Loading...";
  spinnerElement.appendChild(spinnerText);
  spinner.appendChild(spinnerElement);

  return spinner;
}

async function initMap() {
  const properties = await fetchProperties();
  updateMap(properties);
}

function updateMap(properties) {
  const mapPin = "/map-marker.svg";
  const centerMap = { lat: 39.0, lng: -100.0 };
  const mapOptions = {
    center: centerMap,
    zoom: 3,
    styles: [
      {
        featureType: "all",
        elementType: "labels.text",
        stylers: [{ color: "#878787" }],
      },
      {
        featureType: "all",
        elementType: "labels.text.stroke",
        stylers: [{ visibility: "off" }],
      },
      {
        featureType: "landscape",
        elementType: "all",
        stylers: [{ color: "#f9f5ed" }],
      },
      {
        featureType: "road.highway",
        elementType: "all",
        stylers: [{ color: "#f5f5f5" }],
      },
      {
        featureType: "road.highway",
        elementType: "geometry.stroke",
        stylers: [{ color: "#c9c9c9" }],
      },
      {
        featureType: "water",
        elementType: "all",
        stylers: [{ color: "#aee0f4" }],
      },
    ],
  };
  const map = new google.maps.Map(
    document.getElementById("map-search"),
    mapOptions
  );

  const bounds = new google.maps.LatLngBounds();
  let openInfoWindow = null;

  const markers = properties
    .map((property) => {
      const lat = parseFloat(property.latitude);
      const lng = parseFloat(property.longitude);

      if (!isNaN(lat) && !isNaN(lng)) {
        const marker = new google.maps.Marker({
          position: { lat: lat, lng: lng },
          icon: mapPin,
        });

        let rentDisplay;
        if (
          property.rentSchedules &&
          Object.keys(property.rentSchedules).length > 0
        ) {
          const rents = Object.values(property.rentSchedules).map(
            (schedule) => schedule.monthlyRent || schedule
          );
          const minRent = Math.min(...rents);
          const maxRent = Math.max(...rents);
          rentDisplay = `$${minRent} - $${maxRent}`;
        } else {
          rentDisplay = `$${property.rent}`;
        }

        const availableDate = property.available_date
          ? `${property.available_date.month}/${property.available_date.year}`
          : "N/A";
        const formattedArea =
          property.area > 0 ? property.area.toLocaleString("en-US") : "";

        const infoWindowContent = `
          <div class="custom-map-infobox" style="font-family: Arial, sans-serif; max-width: 260px; position: relative; padding: 0;">
            <!-- Desktop Version -->
            <div class="d-none d-lg-block m-2">
              <div style="position: relative; margin-bottom: 10px;">
                <img class="img-fluid rounded-1" src="${
                  property.images[0] || ""
                }" style="width: 100%; max-width: 320px; max-height: 160px; display: block; margin: 0 auto; object-fit: cover;">
              </div>
              <div style="font-size: 16px; font-weight: bold; color: #007bff; text-align: center; padding: 0 5px; margin-bottom: 10px;">
                <a href="/homes/${
                  property.id
                }" style="text-decoration: none; color: #007bff;" tabindex="0">
                  ${property.heading}
                </a>
              </div>

              <div class="text-center" style="font-size: 14px;"><strong>Rent:</strong> <span style="font-weight: bold;">${rentDisplay}</span></div>
              <div class="text-center" style="font-size: 14px;"><strong>Available:</strong> <span style="font-weight: bold;">${availableDate}</span></div>

              <div class="row d-flex align-content-center justify-content-center mb-3">
                <div class="col-auto py-1 px-2">
                  <span class="text-extra-bold text-dark-grey">${
                    property.bedrooms
                  }</span><span class="text-muted"> beds</span>
                  <span class="separator text-small mx-1" aria-hidden="true"> ● </span>
                  <span class="text-extra-bold text-dark-grey">${
                    property.bathrooms
                  }</span><span class="text-muted"> baths</span>
                  <span class="separator mx-1" aria-hidden="true"> ● </span>
                  <span class="text-extra-bold text-dark-grey">${formattedArea}</span><span class="text-muted"> sqft</span>
                </div>
              </div>
            </div>
            <!-- Mobile Version -->
            <div class="d-block d-lg-none m-2">
              <div class="text-center mb-2" style="font-size: 13px; font-weight: bold;">
                <a href="/homes/${
                  property.id
                }" style="text-decoration: none; color: #007bff;" tabindex="0">
                  ${property.heading}
                </a>
              </div>
              <div class="row g-0">
                <div class="col-4">
                  <img class="img-fluid rounded-1" src="${
                    property.images[0] || ""
                  }" style="width: 100%; height: 80px; object-fit: cover;">
                </div>
                <div class="col-8 ps-2" style="font-size: 12px;">
                  <div><strong>Rent:</strong> <span style="font-weight: bold;">${rentDisplay}</span></div>
                  <div><strong>Available:</strong> <span style="font-weight: bold;">${availableDate}</span></div>
                  <div><span class="text-extra-bold text-dark-grey">${
                    property.bedrooms
                  }</span><span class="text-muted"> beds</span></div>
                  <div><span class="text-extra-bold text-dark-grey">${
                    property.bathrooms
                  }</span><span class="text-muted"> baths</span></div>
                  <div><span class="text-extra-bold text-dark-grey">${formattedArea}</span><span class="text-muted"> sqft</span></div>
                </div>
              </div>
            </div>
          </div>
        `;

        const infoWindow = new google.maps.InfoWindow({
          content: infoWindowContent,
          maxWidth: 260,
        });

        google.maps.event.addListener(marker, "click", () => {
          if (openInfoWindow) {
            openInfoWindow.close();
          }
          infoWindow.open(map, marker);
          openInfoWindow = infoWindow;
        });

        google.maps.event.addListener(infoWindow, "closeclick", () => {
          openInfoWindow = null;
        });

        bounds.extend(new google.maps.LatLng(lat, lng));
        return marker;
      }
    })
    .filter((marker) => marker !== undefined);

  if (markers.length > 0) {
    map.fitBounds(bounds);
  } else {
    map.setCenter({ lat: 39.0, lng: -100.0 });
    map.setZoom(3);
  }

  new MarkerClusterer(map, markers, {
    imagePath:
      "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m",
  });
}

const style = document.createElement("style");
style.innerHTML = `
    .gm-style-iw {
        width: auto !important;
        height: auto !important;
        top: 0 !important;
        left: 0 !important;
        box-sizing: border-box;
    }

    .gm-style-iw-c {
        padding: 0 !important;
        background: transparent !important;
        overflow: visible !important;
    }

    .gm-style-iw-ch {
        padding: 0 !important;
        background: transparent !important;
        overflow: visible !important;
    }

    .gm-style-iw-chr {
        padding: 0 !important;
        background: white !important;
        border-radius: 50%;
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 10px;
        right: 10px;
        z-index: 33;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
        transform: translate(20px, -20px);
    }

    .gm-style-iw-d {
        overflow: visible !important;
        background: white !important;
        border-radius: 8px;
    }

    .custom-map-infobox img {
        margin: 0 auto;
        display: block;
    }
  `;
document.head.appendChild(style);

document.addEventListener("turbo:load", function () {
  const toggleMapButton = document.getElementById("toggle-map-button");
  const mapContainer = document.getElementById("map-container-grid");
  const cardsContainer = document.getElementById("cards-container");

  if (toggleMapButton && mapContainer && cardsContainer) {
    toggleMapButton.addEventListener("click", function () {
      mapContainer.classList.toggle("hidden");
      cardsContainer.classList.toggle("full-width");

      if (mapContainer.classList.contains("hidden")) {
        toggleMapButton.innerHTML = `
            <svg class="nav-icon me-2" height="22" width="22" fill="white">
              <use xlink:href="#svg-icon-metro" xmlns:xlink="http://www.w3.org/1999/xlink"></use>
            </svg> Show Map
          `;
        toggleMapButton.setAttribute("aria-expanded", "false");
      } else {
        toggleMapButton.innerHTML = `
            <svg class="nav-icon me-2" height="22" width="22" fill="white">
              <use xlink:href="#svg-icon-metro" xmlns:xlink="http://www.w3.org/1999/xlink"></use>
            </svg> Hide Map
          `;
        toggleMapButton.setAttribute("aria-expanded", "true");
      }
    });
  }
});
