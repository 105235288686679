document.addEventListener("turbo:load", function () {
  let autocompleteData = null;
  let dataFetched = false;

  function fetchAutocompleteData() {
    if (dataFetched) {
      return Promise.resolve(autocompleteData);
    } else {
      dataFetched = true;
      return fetch("/api/autosearch")
        .then((response) => response.json())
        .then((data) => {
          autocompleteData = data;
          return data;
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
          return null;
        });
    }
  }

  fetchAutocompleteData();

  function initializeAutocomplete(inputElement) {
    const container = inputElement.parentElement;
    const clearButton = container.querySelector(".clear-search");
    const suggestionsList = container.querySelector(".suggestion-list");
    const filterButton = document.querySelector(".filter-link");

    if (!clearButton || !suggestionsList) {
      return;
    }

    clearButton.style.display = "none";

    function toggleClearButton() {
      clearButton.style.display =
        inputElement.value.length > 0 ? "block" : "none";
    }

    inputElement.addEventListener("input", function () {
      toggleClearButton();
      const query = inputElement.value.toLowerCase();
      suggestionsList.innerHTML = "";

      if (query.length < 3) {
        return;
      }

      if (!autocompleteData) {
        fetchAutocompleteData().then((data) => {
          if (data) {
            displaySuggestions(data, query);
          }
        });
      } else {
        displaySuggestions(autocompleteData, query);
      }
    });

    if (filterButton) {
      filterButton.addEventListener("click", function () {
        suggestionsList.innerHTML = "";
      });
    }

    function displaySuggestions(data, query) {
      const { cities, properties, metros, states } = data;

      const filteredStates = states.filter((state) =>
        state.name?.toLowerCase().includes(query)
      );
      const filteredMetros = metros.filter((metro) =>
        metro.name?.toLowerCase().includes(query)
      );
      const filteredCities = cities.filter((city) =>
        city.name?.toLowerCase().includes(query)
      );
      const filteredProperties = properties.filter((property) =>
        property.name?.toLowerCase().includes(query)
      );

      if (filteredStates.length > 0) {
        suggestionsList.appendChild(createSuggestionHeader("States"));
        filteredStates.forEach((state) => {
          suggestionsList.appendChild(
            createSuggestionItem(
              '<svg class="me-3" width="18" height="17" fill="#2F92D4"><use xlink:href="#svg-icon-state"></use></svg>',
              state.name,
              state.url,
              query,
              "state",
              state.id
            )
          );
        });
      }

      if (filteredMetros.length > 0) {
        suggestionsList.appendChild(createSuggestionHeader("Metros"));
        filteredMetros.forEach((metro) => {
          const metroUrl = metro.url;

          suggestionsList.appendChild(
            createSuggestionItem(
              '<svg class="me-3" width="18" height="17" fill="#2F92D4"><use xlink:href="#svg-icon-metro"></use></svg>',
              metro.name,
              metroUrl,
              query,
              "metro",
              metro.id
            )
          );
        });
      }

      if (filteredCities.length > 0) {
        suggestionsList.appendChild(createSuggestionHeader("Cities"));
        filteredCities.forEach((city) => {
          suggestionsList.appendChild(
            createSuggestionItem(
              '<svg class="me-3" width="18" height="17" fill="#2F92D4"><use xlink:href="#svg-icon-city"></use></svg>',
              city.name,
              city.url,
              query,
              "city",
              city.id
            )
          );
        });
      }

      if (filteredProperties.length > 0) {
        suggestionsList.appendChild(createSuggestionHeader("Properties"));
        filteredProperties.forEach((property) => {
          suggestionsList.appendChild(
            createSuggestionItem(
              '<svg class="me-3" width="18" height="17" fill="#2F92D4"><use xlink:href="#svg-icon-market"></use></svg>',
              property.name,
              property.url,
              query,
              "property",
              property.id
            )
          );
        });
      }
    }

    clearButton.addEventListener("click", function () {
      inputElement.value = "";
      toggleClearButton();
      inputElement.focus();
      suggestionsList.innerHTML = "";
    });

    function createSuggestionItem(icon, name, url, query, type, id) {
      const suggestionItem = document.createElement("li");
      const iconDiv = document.createElement("div");
      iconDiv.classList.add("icon");
      iconDiv.innerHTML = icon;
      const textDiv = document.createElement("div");
      const highlightedName = name.replace(
        new RegExp(query, "gi"),
        (match) => `<span class="highlight">${match}</span>`
      );
      textDiv.innerHTML = highlightedName;
      textDiv.classList.add("text-content");
      suggestionItem.appendChild(iconDiv);
      suggestionItem.appendChild(textDiv);
      suggestionItem.classList.add("suggestion-item");
      suggestionItem.dataset.url = url;
      suggestionItem.dataset.type = type;
      suggestionItem.dataset.id = id;

      suggestionItem.addEventListener("click", function () {
        inputElement.value = name;
        suggestionsList.innerHTML = "";

        if (type === "metro") {
          const marketSlug = url.split("/metro/")[1];
          const marketInput = document.querySelector("input[name='market']");
          if (marketInput) {
            marketInput.value = marketSlug;
          }

          window.location.href = `/search?market=${encodeURIComponent(
            marketSlug
          )}`;
        } else {
          window.location.href = url;
        }
      });

      return suggestionItem;
    }

    function createSuggestionHeader(text) {
      const header = document.createElement("li");
      header.textContent = text;
      header.classList.add("suggestion-header");
      return header;
    }

    toggleClearButton();
  }

  const autocompleteInputs = document.querySelectorAll(".autocomplete-input");
  autocompleteInputs.forEach((inputElement) => {
    initializeAutocomplete(inputElement);
  });
});
