$(() => {
  let modal = $("#campaign_modal");
  if (modal) {
    modal.modal();
    let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    let date = Date.parse(modal.find("#campaign_modal_expiration").text());
    if (!isNaN(date)) {
      modal.find("#campaign_modal_expiration").html(
        Intl.DateTimeFormat("en-US", {
          dateStyle: "full",
          timeZone: timezone,
        }).format(date)
      );
    }
    modal.on("hidden.bs.modal", function (_) {
      $.ajax({
        url: "api/campaigns/hide_modal",
        type: "post",
        data: {},
        success: function (_) {},
      });
    });
    modal.find("form").on("submit", function () {
      modal.modal("hide");
    });
  }
});

document.addEventListener("turbo:load", function () {
  const rentInfoButtons = document.querySelectorAll(".rent-info");

  rentInfoButtons.forEach(function (button) {
    button.addEventListener("click", function () {
      const closestMeta = this.closest(".c-search-result__meta");
      if (closestMeta) {
        const rentInfoList = closestMeta.querySelector(".rent-info-list");
        if (rentInfoList) {
          rentInfoList.classList.toggle("d-none");
        }
      }
    });
  });
});
document.addEventListener("turbo:load", function () {
  if (window.location.pathname.includes("/homes/")) {
    const similarHomesEndpointTemplate =
      "/api/properties/${propertyId}/similar?limit=10";
    const nearbyHomesEndpointTemplate = "/api/properties/${propertyId}/near";

    initializeCarousel(
      ".similar-homes-container",
      ".card-container",
      ".similar-homes-title",
      similarHomesEndpointTemplate,
      "Similar Properties",
      []
    ).then((similarProperties) => {
      initializeCarousel(
        ".nearby-homes-container",
        ".card-container-nearby",
        ".nearby-homes-title",
        nearbyHomesEndpointTemplate,
        "Nearby Properties",
        similarProperties
      );
    });
  }

  trackPropertyClicks();
});

function trackPropertyClicks() {
  document.addEventListener("click", function (event) {
    if (event.target.closest(".property-link")) {
      const propertyLink = event.target.closest(".property-link");
      const propertyCard = propertyLink.closest(".property-card");
      const propertyId = propertyCard.dataset.propertyId;
      const propertyCategory = propertyCard.dataset.propertyCategory;

      if (typeof gtag === "function") {
        gtag("event", "click_property", {
          category: `Directed from ${propertyCategory}`,
          action: "click",
          label: propertyId,
          access_point: propertyCategory,
        });
      }
    }
  });
}

async function initializeCarousel(
  containerSelector,
  cardContainerSelector,
  titleSelector,
  apiEndpointTemplate,
  titleText,
  excludeProperties
) {
  try {
    const element = document.querySelector(containerSelector);

    if (!element) {
      console.error(`Element ${containerSelector} not found in the DOM.`);
      return;
    }

    const cardContainer = element.querySelector(cardContainerSelector);
    const homesTitle = element.querySelector(titleSelector);
    const propertyId = element.dataset.propertyId;

    if (!cardContainer || !homesTitle || !propertyId) {
      console.error(
        `Sub-elements not found in the DOM for ${containerSelector}`
      );
      return;
    }

    const apiEndpoint = apiEndpointTemplate.replace(
      "${propertyId}",
      propertyId
    );

    async function fetchProperties(url) {
      try {
        const response = await fetch(url);
        const data = await response.json();
        return data;
      } catch (error) {
        console.error("Error fetching properties:", error);
        return [];
      }
    }

    function filterProperties(properties, excludeProperties) {
      return properties.filter(
        (property) =>
          !excludeProperties.some(
            (excludeProperty) => excludeProperty.id === property.id
          )
      );
    }

    function generateCards(container, properties, category) {
      container.innerHTML = "";

      properties.forEach((property) => {
        const card = document.createElement("div");
        card.className = "item property-card";
        card.dataset.propertyId = property.id;
        card.dataset.propertyCategory = category;

        const formattedRentPrice = property.rent_price
          ? `$${Math.round(property.rent_price).toLocaleString("en-US")}`
          : "";

        const formattedArea =
          property.area > 0
            ? Math.round(property.area).toLocaleString("en-US")
            : "";

        card.innerHTML = `
          <div class="card h-100 d-flex flex-column border c-search-result__image position-relative">
            <a href="${
              property.url
            }" class="d-block aspect-ratio-16-9 position-relative property-link">
              <img src="${property.image}" alt="Home for rent in ${
          property.city
        }, ${property.state}" class="card-img-top relative">
              ${
                property.availability === "Now"
                  ? '<div class="top-cross-ribbon">Move In Now!</div>'
                  : ""
              }
            </a>
            <div class=" m-2 rounded-2">
              ${
                property.rent_price
                  ? `
              <div class="col-12  d-flex justify-content-between">
                <div><span class="h5 text-extra-bold text-dark-grey">${formattedRentPrice}</span>
                <span class="text-extra-small text-muted">/mo</span></div>
                <span>${
                  property.availability
                    ? `<div class="d-flex gap-2 align-items-end"><span class="text-extra-small text-muted">Available</span><span class="text-extra-bold px-1 rounded-1 text-small text-primary-hpa-400 bg-extra-light-blue no-wrap">${property.availability}</span></div>`
                    : ""
                }</span>
              </div>`
                  : ""
              }
              <div class="row small">
                <div class="col-auto pe-1">
                  ${
                    property.beds
                      ? `<span class="text-extra-bold text-dark-grey text-small">${property.beds}</span><span class="text-muted text-extra-small"> beds</span>`
                      : ""
                  }
                  ${
                    property.bathrooms
                      ? `<span class="separator text-small mx-1" aria-hidden="true"> ● </span><span class="text-extra-bold text-dark-grey text-small">${property.bathrooms}</span><span class="text-muted text-extra-small"> baths</span>`
                      : ""
                  }
                  ${
                    formattedArea
                      ? `<span class="separator mx-1" aria-hidden="true"> ● </span><span class="text-extra-bold text-dark-grey text-small">${formattedArea}</span><span class="text-muted text-extra-small"> sqft</span>`
                      : ""
                  }
                </div>
              </div>
              <div class="small  m-0 mx-0  ">
                <a href="${
                  property.url
                }" class="text-muted property-link flex-grow-1 pre-line text-extra-small pe-3 ellipsis">${
          property.name
        }</a>
              </div>
            </div>
          </div>
        `;
        container.appendChild(card);
      });

      $(container).owlCarousel({
        margin: 10,
        responsiveClass: true,
        responsive: {
          0: {
            items: 1,
            nav: true,
            dots: false,
          },
          620: {
            items: 2,
            nav: true,
          },
          1080: {
            items: 3,
            nav: true,
          },
          1400: {
            items: 4,
            nav: true,
          },
          1900: {
            items: 5,
            nav: true,
          },
        },
      });

      trackPropertyClicks();
    }

    const properties = await fetchProperties(apiEndpoint);
    const filteredProperties = filterProperties(properties, excludeProperties);

    if (filteredProperties.length === 0) {
      element.style.display = "none";
    } else {
      homesTitle.innerHTML = `${titleText} (${filteredProperties.length})`;
      generateCards(
        cardContainer,
        filteredProperties,
        titleText.toLowerCase().includes("nearby") ? "nearby" : "similar"
      );
    }

    return properties;
  } catch (error) {
    console.error("An error occurred in initializeCarousel:", error);
  }
}

document.addEventListener("turbo:load", function () {
  const toggleMapVisibility = (buttonId, mapContainerId, gridContainerId) => {
    const button = document.getElementById(buttonId);
    const mapContainer = document.getElementById(mapContainerId);
    const gridContainer = document.getElementById(gridContainerId);

    if (button && mapContainer && gridContainer) {
      button.addEventListener("click", function () {
        mapContainer.classList.toggle("d-none");
        if (mapContainer.classList.contains("d-none")) {
          gridContainer.classList.add("centered-grid");
          gridContainer.classList.remove("col-lg-7");
          gridContainer.classList.add("col-lg-12");
          button.textContent = "Show map";
        } else {
          gridContainer.classList.remove("centered-grid");
          gridContainer.classList.remove("col-lg-12");
          gridContainer.classList.add("col-lg-7");
          button.textContent = "Hide map";
        }
      });
    }
  };

  toggleMapVisibility("show-map-button-lg", "map-container", "cards-container");
  toggleMapVisibility(
    "show-map-button-sm",
    "map-container-mobile",
    "cards-container-mobile"
  );
});
